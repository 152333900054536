<template>
  <div :class="isMobile ? 'footer-m' : 'footer'">
    <div class="footer-content">
      <div class="logo">
        <img src="./../assets/logo.png" />
      </div>
      <div class="product-list">
        <span>产品</span>
        <el-divider direction="vertical"></el-divider>
        <span>气体传感器</span>
        <el-divider direction="vertical"></el-divider>
        <span>传感器模组</span>
        <el-divider direction="vertical"></el-divider>
        <span>环境气氛监测系统</span>
        <el-divider direction="vertical"></el-divider>
        <span>设备标定和校准</span>
        <el-divider direction="vertical"></el-divider>
        <span>空气质量检测服务</span>
        <el-divider direction="vertical"></el-divider>
        <span>解决方案</span>
      </div>
      <el-row>
        <el-col :span="8">
          <ul>
            <li>公司：艾感科技（广东）有限公司</li>
            <li>邮编：528000</li>
            <li>地址：佛山市禅城区张槎街道华兴路22号顺盈嘉智能科技园C座八楼C801</li>
          </ul>
        </el-col>
        <el-col :span="6" :offset="1">
          <ul>
            <li>电话：13318386725</li>
            <li>邮箱：aigankejizhouzong@aisensing.cn</li>
            <li><el-button type="text" @click="clickBtn" class="btnLink">艾感网络聚合索引</el-button></li>
          </ul>
        </el-col>
        <el-col :span="6"><div class="grid-content bg-purple-light"></div></el-col>
        <el-col :span="6">
          <div class="title">扫一扫关注我们</div>
          <ul>
            <li class="li-code"><img class="wechat-public" src="./../assets/code.png" alt=""></li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <div class="icp">
      <a href="https://beian.miit.gov.cn" target="_blank">艾感科技（广东）有限公司 粤ICP备18012156号</a>
    </div>

    <el-dialog
      title="艾感网络聚合索引列表"
      :visible.sync="dialogVisible"
      :width="isMobile ? '95%' : ''"
      class="dialog"
      :before-close="handleClose">
      <div>
        <div style="margin-top: 15px;">
          <el-input placeholder="请输入编码" v-model="inputValue" size="mini" class="linkInput" id="enterCode">
          </el-input>
          <el-button size="mini" @click="searchLink">搜索</el-button>
        </div>
        <el-tabs v-model="activeTab" v-show="linkIsShow" class="dialogContent" type="border-card">
          <el-tab-pane label="网站聚合索引列表" name="0">
            <div class="linkList" id="linkList" >
              <span v-for="item in linkList" class="oneLink" :key="item.name"><a target="_blank" :href="item.url">{{item.name}}</a></span>
            </div>
          </el-tab-pane>
          <el-tab-pane label="流量统计" name="1">
            <el-table
              :data="tableData"
              style="width: 100%">
              <el-table-column
                prop="name"
                label="页面"
                width="220">
                <template slot-scope="scope">
                  <div >{{ scope.row.name }}</div>
                  <div class="table-subname">{{ scope.row.subname }}</div>
                </template>
              </el-table-column>
              <el-table-column
                prop="hits"
                label="总流量">
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
  </div>
</template>

<script>
import arrJson from '@/utils/arrJson'

export default {
  name: 'Foot',
  props: {
    msg: String
  },
  data() {
    return {
      isMobile: window.innerWidth < 780,
      dialogVisible: false,
      inputValue: "",
      linkIsShow: false,
      activeTab: "0",
      tableData: [],
      linkList: arrJson.footLinkList
    }
  },
  mounted() {
    window.addEventListener("resize", this.initFunc)
    this.getHits()
  },
  destroyed() {
    window.removeEventListener("resize", this.initFunc)
    this.removeListen()
  },
  methods: {
    handleClose(done) {
      this.linkIsShow = false
      this.inputValue = ""
      this.removeListen()
      done()
    },
    clickBtn() {
      this.dialogVisible = true
      this.addListen()
    },
    enterEvent(event){
      if (event.key === 'Enter') {
        this.searchLink(true)
      }
    },
    addListen() {
      document?.addEventListener('keydown', this.enterEvent)
    },
    removeListen() {
      document?.removeEventListener('keydown', this.enterEvent)
    },
    getHits() {
      this.tableData = [{
        name: '官网',
        subname: '(2023-10-12开始统计)',
        hits: 5224
      }, {
        name: '艾感污染源监测',
        subname: '（原瀚蓝恶臭）',
        hits: 158
      }, {
        name: '艾感室内气体监测设备',
        subname: '(甲醛蓝牙设备)',
        hits: 1328
      }, {
        name: '大气污染智能化监控',
        subname: '原南庄镇大气污染监测',
        hits: 240
      }, {
        name: '空气监测平台',
        hits: 789
      }, {
        name: 'feelT智能运动鞋垫',
        hits: 355
      }, {
        name: '艾感室内空气监测',
        subname: '(手持设备)',
        hits: 262
      }, {
        name: '生产跟踪系统',
        hits: 84
      }]
    },
    searchLink(type) {
      if(this.inputValue === "Aigan") {
        this.linkIsShow = true
      } else {
        if(type && this.inputValue === ""){
          return false
        }
        this.isMobile ? 
        this.$dialog.alert({
          message: '编码错误，请重新输入',
        }) :
        this.$message({
          message: '编码错误，请重新输入',
          type: 'warning'
        });
      }
    },
    initFunc() {
      this.isMobile = window.innerWidth < 780
    }
  }
}
</script>


<style scoped lang="less">
.footer{
  background: #0b55dc;
  color: #fefefe;
  .footer-content{
    background: #0b55dc;
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px 0 0;
    text-align: left;
  }
  .logo{
    display: inline-block;
    vertical-align: middle;
    width: 180px;
    img{
      width: 90%;
      display: inline-block;
    }
  }
  .product-list{
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 60px;
    font-size: 14px;
  }
  .el-divider--vertical{
    margin: 0 16px;
  }
  .el-row{
    margin: 30px 0;
  }
  .dialogContent{
    margin-top: 20px;
  }
  .icp{
    line-height: 40px;
    color: #fff;
    font-size: 14px;
    background: #02399e;
    text-align: center;
  }
  .title{
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
  ul{
    margin: 0;
    padding: 0;
  }
  li{
    font-size: 13px;
    line-height: 30px;
    list-style: none;
    color: #ffffffc9;
    text-align: left;
    word-break: break-all;
  }
  .li-code{
    text-align: center;
  }
  .wechat-public{
    width: 40%;
    margin: 0;
  }
  .btnLink{
    color: #ffffffc9;
  }
  .dialog{
    text-align: left;
    /deep/.el-dialog{
      width: 620px !important;
    }
    .linkList{
      margin: 10px 0;
      text-align: left;
    }
    .linkList .oneLink {
      display: block;
      font-size: 14px;
      line-height: 30px;
      color: #0e2a3c;
      a{
        color: #00000099;
      }
    }
    .link-body{
      margin: 0 0 50px;
    }
    .linkInput{
      display: inline-block;
      width: 200px;
    }
    .table-subname{
      color: #a5a5a5;
      font-size: 12px;
      line-height: 14px;
    }
  }
}

  //移动端
    .footer-m{
      font-size: 0.6rem;
      color: #ebf0fa;
      line-height: 0.68rem;
      .footer-content{
        background: #0b55dc;
        padding: 0.4rem;
        .logo{
          width: 86%;
          margin: 0.2rem auto;
        }
        img{
          width: 60%;
          margin: 0 auto;
          display: block;
        }
        .product-list{
          margin: 0.4rem 0;
          font-size: 0.36rem;
          color: #ffffffd4;
          .el-divider--vertical{
            margin: 0 0.24rem;
          }
        }
        .el-row{
          ul{
            padding: 0;
          }
          li{
            list-style: none;
            font-size: 0.4rem;
            line-height: 0.7rem;
            color: #ffffff;
            .btnLink{
              color: #fff;
            }
          }
          .el-col{
            width: 100%;
            margin: 0;
            .title{
              font-size: 0.4rem;
              color: #ffffffd0;
              text-align: center;
            }
          }
          .el-button{
            font-size: 0.4rem;
            line-height: 0.78rem;
          }
        }
      }
      .dialogContent{
        margin-top: 0.5rem;
      }
      .icp{
        font-size: 0.34rem;
        line-height: 0.8rem;
        background: #02399e;
        text-align: center;
      }
      .dialog{
        .linkInput{
          width: 80% !important;
        }
        /deep/ .el-dialog__title{
          font-size: 0.4rem;
          line-height: 1rem;
        }
        /deep/ .el-dialog__body{
          padding: 0.2rem 0.2rem;
        }
        /deep/ .el-dialog__headerbtn{
          font-size: 0.4rem;
        }
        /deep/ .el-input--mini{
          font-size: 0.3rem;
          .el-input__inner {
            line-height: 1rem;
            height: 1rem;
            font-size: 0.3rem;
          }
        }
        .el-button--mini {
          font-size: 0.3rem;
          line-height: 0.6rem;
          padding: 0.2rem 0.4rem;
        }
        .linkList {
          .oneLink[data-v-02065d92] {
            font-size: 0.4rem;
            line-height: 1rem;
            display: block;
          } 
        }
        .table-subname{
          font-size: 0.34rem;
          color: #9a9a9a;
          line-height: 0.42rem;
        }
      }
    }



</style>
